<template>
    <div class="pt-7 container-fluid">
        <b-card>
            <base-button type="success" @click="uploadModal" class="my--5">
                <i class="fas fa-plus"></i> {{$t('general.file_upload')}}
            </base-button>
        </b-card>
        <b-card no-body class="p-4">
            <b-row>
                <b-col md="2" class="mb-4" v-for="(item, index) in files" :key="index">
                    <div class="file-section">
                        <div class="file-name">
                            <a :title="item.originalName">{{item.originalName}}</a>
                        </div>
                        <div class="file-date">{{item.createdAt | getDate}}</div>
                        <div class="file-icon">
                            <i class="fas fa-file-image" v-if="item.type=='image'"></i>
                            <i class="fas fa-file-alt" v-else></i>
                        </div>
                        <div class="file-footer">
                            <base-button type="primary" size="sm" @click="openFile(item.uuid)">{{$t('general.open')}}</base-button>
                            <base-button type="danger" size="sm" @click="deleteFile(item.uuid)">{{$t('general.delete')}}</base-button>
                        </div>
                    </div>
                </b-col>
            </b-row>
            <b-alert :show="!loading&&files.length==0" variant="info">
                {{$t('general.empty_files')}}
            </b-alert>
        </b-card>

        <modal :show.sync="modal.status" :showClose="false" :backdropClose="false" :hideFooter="true" @close="modal.status=false">
            <h6 slot="header" class="modal-title">{{$t('general.file_upload')}}</h6>
            <b-form-file accept="image/jpeg, image/png, application/pdf" size="sm" v-model="modal.file" :placeholder="$t('general.select_file')" :drop-placeholder="$t('general.drop_file')" multiple :file-name-formatter="formatNames" :browse-text="$t('general.choose')" ref="file"></b-form-file>
            <p><br>
                <base-button type="primary" size="sm" v-if="modal.file&&modal.file.length>0" @click="uploadPhoto">{{$t('general.upload')}}</base-button>
            </p>
            <base-button type="danger" size="sm" class="ml-auto float-right" @click="modal.status=false">{{$t('general.close')}}</base-button>
        </modal>
    </div>
</template>

<script>
import { PATIENT_FILES, PATIENT_UPLOAD, PATIENT_FILE_DELETE } from '@/store/modules/define';
import { handleError } from '@/utils/func';
import { mapGetters } from 'vuex';
import { getCookie } from '@/utils/cookie';

const token = getCookie('user-token');
const session = getCookie('user-session');
let self;
export default {
    computed: {
        ...mapGetters({ files: 'getFiles' })
    },
    data() {
        return {
            cdnUrl: process.env.VUE_APP_CDNURL,
            modal: {
                loading: false,
                status: false,
                file: null
            },
            loading: false,
            pagination: {
                limit: 10,
                page: 1
            },
            filter: {}
        };
    },
    methods: {
        openFile(uuid) {
            var xhr = new XMLHttpRequest();

            xhr.open('GET', self.cdnUrl + '/' + uuid);
            xhr.onreadystatechange = () => {
                if (xhr.status == 200 && xhr.response) {
                    var data_url = URL.createObjectURL(xhr.response);
                    window.open(data_url);
                }
            };
            xhr.responseType = 'blob';
            xhr.setRequestHeader('Authorization', token);
            xhr.setRequestHeader('Session', session);
            xhr.send();
        },
        deleteFile(uuid) {
            this.$swal({
                title: self.$i18n.t('quest.sure'),
                text: self.$i18n.t('quest.delete_info'),
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#f5365c',
                confirmButtonText: self.$i18n.t('quest.delete'),
                cancelButtonText: self.$i18n.t('quest.cancel')
            }).then(result => {
                if (result.value) {
                    self.loading = true;
                    self.$store
                        .dispatch(PATIENT_FILE_DELETE, uuid)
                        .then(resp => {
                            self.loading = false;
                            self.getData();
                            self.$notify({ type: 'success', message: self.$i18n.t('success.positive') });
                        })
                        .catch(err => {
                            self.loading = false;
                            handleError(self, err);
                        });
                }
            });
        },
        uploadModal() {
            self.modal = {
                loading: false,
                status: true,
                file: null
            };
        },
        uploadPhoto() {
            self.modal.loading = true;
            self.$store
                .dispatch(PATIENT_UPLOAD, { file: self.modal.file })
                .then(resp => {
                    self.modal.loading = false;
                    self.modal.status = false;
                    self.getData();
                    self.$notify({ type: 'success', message: self.$i18n.t('success.positive') });
                })
                .catch(err => {
                    self.modal.loading = false;
                    handleError(self, err);
                });
        },
        formatNames(files) {
            return files.length === 1 ? files[0].name : `${files.length} ${self.$i18n.t('general.files_selected')}`;
        },
        getData() {
            self.loading = true;
            self.$store
                .dispatch(PATIENT_FILES)
                .then(resp => {
                    self.loading = false;
                })
                .catch(err => {
                    self.loading = false;
                    handleError(self, err);
                });
        }
    },
    created() {
        self = this;
        self.getData();
    }
};
</script>
